<template>
  <Layout navbarTransparent>
    <Header :image="current.procat_image" :header="current.procat_desc" :header_eng="current.procat_desc_en" />

    <div class="my-4"></div>

    <div class="container" id="content-layout" style="max-width:1000px"></div>

    <div class="d-flex align-items-center justify-content-center w-100">
      <div class="text-center position-relative w-100">
        <img
          src="@/static/assets/Group 683.png"
          class="w-100 position-absolute"
          style="height: auto; left: 0px; top: 10%; z-index: -1"
        />
        <div class="container d-flex pt-2" style="min-height: 400px">
          <div class="my-auto mx-auto pt-" style="" :id="`content`">
            <img
              :src="current.procat_content_image"
              class="w-100"
              style="max-height: 600px; margin-bottom: 10%"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="container mb-5 pb-5">
      <div class="d-flex" style="gap: 20px">
        <template v-for="(data, idx) in list">
          <div
            class="flex-fill p-1 rounded-lg"
            style="background: #e8f1f8; border-radius: 16px"
            :key="data.pl4_id + idx"
          >
            <div class="row align-items-center">
              <div class="col-auto">
                <img :src="data.pl4_image" class="w-100" style="max-width: 100px" />
              </div>
              <div class="col">
                <div v-html="data.pl4_content"></div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/layouts/main.vue";
import Header from "./header.vue"; 

import store from "@/store";

import {
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BTabs,
  BTab,
  BEmbed,
} from "bootstrap-vue";

export default {
  components: {
    Header, 

    Layout,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BTabs,
    BTab,
    BEmbed,
  },
  mounted() {
    this.getNextDatas();
  },
  computed: {
    currentLocale() {
      return this.$store.state.locales.CURRENT;
    },
  },
  watch: {
    currentLocale(val) {
      this.getNextDatas();
    },
  },
  methods: {
    async getNextDatas(){
      this.current = await store.dispatch("product_categories/getDataList", {
        id: this.$route.params.id,
      });
      this.list = await store.dispatch("product_categories/getDataLayout4", {
        id: this.$route.params.id,
        langcode: this.currentLocale,
      });
      
      if(this.currentLocale == 'EN')
        document.getElementById("content-layout").innerHTML = this.current.procat_content_en;
      else 
        document.getElementById("content-layout").innerHTML = this.current.procat_content;
    }
  },
  data() {
    return {
      current: {},
      list: [],
    };
  },
};
</script>

<style>
.nav-tabs .nav-link.active {
  color: #ef6d22;
}
.nav-tabs .nav-link.active::after {
  background: #ef6d22 !important;
  height: 5px;
  border-radius: 3px;
}
</style>
